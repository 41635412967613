import { createRoot } from "react-dom/client";
import { App } from "./App";
// Add the following code snippet to your application before any other JavaScript/TypeScript code!
// For example put the code in your root index.[ts|js] file, right before you initialize your SPA / App.

import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

if (process.env.FARO === "true") {
  var faro = initializeFaro({
    url: "https://faro-collector-prod-eu-west-2.grafana.net/collect/7cb775ce4d412b64f85457b08c3ea987",
    app: {
      name: "k5",
      version: "1.0.0",
      environment: "production",
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      new TracingInstrumentation(),
    ],
  });
}
const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
