import XmlBeautify from "xml-beautify";

const IMPORT_TEXT = "IMPORT_TEXT";
const IMPORT_YEAR = "BOOKING_YEAR";
const IMPORT_DATE = "IMPORT_DATE";
const IMPORT_CIRCLE = "IMPORT_CIRCLE";
const IMPORT_NUMBER = "IMPORT_NUMBER";
const IMPORT_TYPE = "IMPORT_TYPE";
const IMPORT_AMOUNT = "IMPORT_AMOUNT";
const IMPORT_FULL_TEXT = "IMPORT_FULL_TEXT";
const IMPORT_HINT = "IMPORT_HINT";
const IMPORT_BEGINNING = "IMPORT_BEGINNING";
const IMPORT_POST = "IMPORT_POST";
const IMPORT_SUB = "IMPORT_SUB";
const IMPORT_ENTRIES = "IMPORT_ENTRIES";
const ASSET_LIABILITY_HINT = 0;
const INCOME_EXPENSES_HINT = 1;

const TEXT_XML_TEMPLATE = `<base:BuchungsText>${IMPORT_TEXT}</base:BuchungsText>`;
const ROOT_XML_TEMPLATE = `<?xml version="1.0" encoding="UTF-8"?>
<K5BuchungImport xmlns="http://www.k-5.at/Schema/Fibu/Import:003" xmlns:base="http://www.k-5.at/Schema/Fibu/BaseTypes:003" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
   <Buchungen Jahr="${IMPORT_YEAR}">
${IMPORT_ENTRIES}
   </Buchungen>
</K5BuchungImport>`;
const ENTRY_XML_TEMPLATE = `<Buchung>
<HHBuchung>
   <base:BuchungsDaten>
      <base:BuchungsDatum>${IMPORT_DATE}</base:BuchungsDatum>
      <base:Belegkreis>${IMPORT_CIRCLE}</base:Belegkreis>
      <base:BelegNummer>${IMPORT_NUMBER}</base:BelegNummer>
   </base:BuchungsDaten>
   <base:BuchungsArt>${IMPORT_TYPE}</base:BuchungsArt>
   <base:Betrag>${IMPORT_AMOUNT}</base:Betrag>
   <base:BuchungsTexte>
      ${IMPORT_FULL_TEXT}
   </base:BuchungsTexte>
   <HHKonto>
      <base:Hinweis>${IMPORT_HINT}</base:Hinweis>
      <base:Ansatz>${IMPORT_BEGINNING}</base:Ansatz>
      <base:Post>${IMPORT_POST}</base:Post>
      <base:Untergliederung>${IMPORT_SUB}</base:Untergliederung>
   </HHKonto>
</HHBuchung>
</Buchung>`;

// Account numbers from 4 to 9 are for income and expenses and 0 to 3 are for assets and liabilities
export function createAccountHint(account) {
  const accountString = account.toString();
  const HIGHEST_ASSET_LIABILITY_CLASS = 3;
  const accountClass = parseInt(accountString.charAt(0), 10);
  return accountClass <= HIGHEST_ASSET_LIABILITY_CLASS
    ? ASSET_LIABILITY_HINT
    : INCOME_EXPENSES_HINT;
}

export function getBookingYear() {
  return new Date().getFullYear().toString();
}

function createK5Amount(amount) {
  return (amount / 100).toFixed(2);
}

function entry2K5entry(entries) {
  let k5Entries = [];
  for (let i = 0; i < entries.length; i++) {
    let k5 = {};
    k5.date = new Date().toISOString().split("T")[0];
    k5.circle = "0";
    k5.number = "0";
    k5.type = "RechnungZahlung";
    k5.amount = createK5Amount(entries[i].amount);
    k5.text = importText2XML(entries[i].text);
    k5.hint = createAccountHint(entries[i].account);
    k5.beginning = entries[i].costCenter;
    k5.post = entries[i].account;
    k5.sub = "000";
    k5Entries.push(k5);
  }
  return k5Entries;
}

export function json2xml(data) {
  let bookingStrings = [];
  let k5Entries = entry2K5entry(data);
  k5Entries.forEach((entry) => {
    bookingStrings.push(
      ENTRY_XML_TEMPLATE.replace(IMPORT_DATE, entry.date)
        .replace(IMPORT_CIRCLE, entry.circle)
        .replace(IMPORT_NUMBER, entry.number)
        .replace(IMPORT_TYPE, entry.type)
        .replace(IMPORT_AMOUNT, entry.amount)
        .replace(IMPORT_FULL_TEXT, entry.text)
        .replace(IMPORT_HINT, entry.hint)
        .replace(IMPORT_BEGINNING, entry.beginning)
        .replace(IMPORT_POST, entry.post)
        .replace(IMPORT_SUB, entry.sub),
    );
  });
  let K5_XML = ROOT_XML_TEMPLATE.replace(IMPORT_YEAR, getBookingYear());
  K5_XML = K5_XML.replace(IMPORT_ENTRIES, bookingStrings.join("\n"));
  return beautifyXml(K5_XML);
}

function beautifyXml(xml) {
  return new XmlBeautify().beautify(xml, {
    indent: " ", //indent pattern like white spaces
    useSelfClosingElement: true, //true:use self-closing element when empty element.
  });
}

function buildEntryText(text) {
  const K5_TEXT_LENGTH_LIMIT = 30;
  const ABBREVIATION = "...";
  // K5 doesn't support well texts above 30 chars, therefore the text gets truncated
  // We return an array of strings instead of a string to be able to later combine the cost centres and texts
  if (text.length > K5_TEXT_LENGTH_LIMIT) {
    const MAXIMUM_TEXT_LENGTH = K5_TEXT_LENGTH_LIMIT - ABBREVIATION.length;
    return [text.substring(0, MAXIMUM_TEXT_LENGTH) + ABBREVIATION];
  } else {
    return text;
  }
}

function importText2XML(inputText) {
  const uniqueTexts = [...new Set(inputText.split("\n"))];
  const processedTexts = uniqueTexts.map((text) => buildEntryText(text));
  const textStrings = processedTexts.map((text) =>
    TEXT_XML_TEMPLATE.replace(IMPORT_TEXT, text),
  );
  return textStrings.join("\n");
}
