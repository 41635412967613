const GENERAL_COST_CENTER = "000000";
const CITY_HALL_COST_CENTER = "010000";
const CIVIL_SERVANT_ACCOUNT = "500000";
const FUTURE_CONTRIBUTION_ACCOUNT = "362800";
const EMPLOYER_CONTRIBUTION_ACCOUNT = "582000";

function removeECardServiceFees(entries) {
  const E_CARD_SERVICE_FEES_ACCOUNT = "999997";
  // Creates a new array with new references for entries not matching the E_CARD service fees account
  return entries
    .filter((entry) => entry.account !== E_CARD_SERVICE_FEES_ACCOUNT)
    .map((entry) => ({ ...entry }));
}

function flipFutureContributionAmounts(entries) {
  return entries.map((entry) => ({
    ...entry,
    amount:
      entry.account === FUTURE_CONTRIBUTION_ACCOUNT
        ? entry.amount * -1
        : entry.amount,
  }));
}

function adjustCostCenters(entries) {
  return entries.map((entry) => ({
    ...entry,
    costCenter: entry.costCenter
      ? entry.costCenter.padStart(6, "0")
      : GENERAL_COST_CENTER,
  }));
}

function adjustCostCenterForFutureContributions(entries) {
  return entries.map((entry) => ({
    ...entry,
    costCenter:
      entry.account === FUTURE_CONTRIBUTION_ACCOUNT
        ? GENERAL_COST_CENTER
        : entry.costCenter,
  }));
}

function adjustCostCenterForCivilServant(entries) {
  return entries.map((entry) => ({
    ...entry,
    costCenter:
      entry.account === CIVIL_SERVANT_ACCOUNT &&
      entry.costCenter === GENERAL_COST_CENTER
        ? CITY_HALL_COST_CENTER
        : entry.costCenter,
  }));
}

// Split SVAGA account into civil servant and elected body contributions
function getSocialSecurityEmployerContributionForCivilServants(entries) {
  // filter out the entries that are not for civil servants
  const SPECIAL_PAYMENT = "Sonderzahlung";
  const NORMAL_CONTRIBUTION_FACTOR = 4005;
  const SPECIAL_PAYMENT_CONTRIBUTION_FACTOR = 3535;
  const SCALING_FACTOR = 100000;
  // Array of strings to be used as the entry text
  const CONTRIBUTION_CIVIL_SERVANT_TEXT = "SV DG-Anteil Beamte";
  let filteredEntries = entries.filter(
    (entry) => entry.account === CIVIL_SERVANT_ACCOUNT,
  );
  // if there are no entries for civil servants we nothing
  if (filteredEntries.length === 0) {
    return null;
  }
  let CONTRIBUTION = filteredEntries.reduce((accumulator, entry) => {
    const factor = entry.text.includes(SPECIAL_PAYMENT)
      ? SPECIAL_PAYMENT_CONTRIBUTION_FACTOR
      : NORMAL_CONTRIBUTION_FACTOR;
    const contributionForEntry = entry.amount * factor; // Adjust back after calculation
    return accumulator + contributionForEntry;
  }, 0);
  CONTRIBUTION = Math.round(CONTRIBUTION / SCALING_FACTOR);

  return {
    account: EMPLOYER_CONTRIBUTION_ACCOUNT,
    costCenter: CITY_HALL_COST_CENTER,
    text: CONTRIBUTION_CIVIL_SERVANT_TEXT,
    amount: CONTRIBUTION,
  };
}

function getElectedBodiesOtherEmployerContributions(
  svagaEntry,
  cvContribution,
) {
  const CONTRIBUTION_ELECTED_BODIES_TEXT = "Gewählte Organe sonstige DG...";
  const CONTRIBUTION = svagaEntry.amount - cvContribution.amount;
  return {
    account: EMPLOYER_CONTRIBUTION_ACCOUNT,
    costCenter: GENERAL_COST_CENTER,
    text: CONTRIBUTION_ELECTED_BODIES_TEXT,
    amount: CONTRIBUTION,
  };
}

function splitSVAGAAccount(entries) {
  const SVAGA_ACCOUNT = "999998";
  // Find the SVAGA entry, if any
  const svagaEntryIndex = entries.findIndex(
    (entry) => entry.account === SVAGA_ACCOUNT,
  );

  // If there is a SVAGA entry, process it
  if (svagaEntryIndex !== -1) {
    const svagaEntry = entries[svagaEntryIndex];

    // Separate SVAGA from the rest
    const otherEntries = entries.filter(
      (_, index) => index !== svagaEntryIndex,
    );

    // Get contributions for civil servants and elected bodies
    const cvContribution =
      getSocialSecurityEmployerContributionForCivilServants(otherEntries);
    const electedContribution = getElectedBodiesOtherEmployerContributions(
      svagaEntry,
      cvContribution,
    );

    if (cvContribution !== null) {
      otherEntries.push(cvContribution);
    }
    if (electedContribution !== null) {
      otherEntries.push(electedContribution);
    }

    // Return the modified list with contributions added
    return otherEntries;
  }
  // Return the original list if no SVAGA entry is present
  return entries;
}

function getPensionInsuranceEmployerContributionForMayor(entries) {
  const MAYOR_SALARY_ACCOUNT = "721000";
  let filteredEntries = entries.filter(
    (entry) => entry.account === MAYOR_SALARY_ACCOUNT,
  );
  // if there are no entries for the mayor we do nothing
  if (filteredEntries.length === 0) {
    return entries;
  }
  const CONTRIBUTION_MAYOR_PENSION_FACTOR = 1105;
  const SCALING_FACTOR = 10000;
  const CONTRIBUTION_AMOUNT = filteredEntries.reduce((accumulator, entry) => {
    const contributionForEntry = Math.round(
      (entry.amount * CONTRIBUTION_MAYOR_PENSION_FACTOR) / SCALING_FACTOR,
    );
    return accumulator + contributionForEntry;
  }, 0);
  const CONTRIBUTION_MAYOR_PENSION_TEXT = "PV DG-Anteil Bgm";
  const CONTRIBUTION_MAYOR_PENSION_ACCOUNT_LIABILITY = "362310";
  const CONTRIBUTION_MAYOR_PENSION_ACCOUNT_EXPENSE = "753000";
  let entry_expense = {
    account: CONTRIBUTION_MAYOR_PENSION_ACCOUNT_EXPENSE,
    costCenter: GENERAL_COST_CENTER,
    text: CONTRIBUTION_MAYOR_PENSION_TEXT,
    amount: CONTRIBUTION_AMOUNT,
  };
  let entry_liability = {
    account: CONTRIBUTION_MAYOR_PENSION_ACCOUNT_LIABILITY,
    costCenter: GENERAL_COST_CENTER,
    text: CONTRIBUTION_MAYOR_PENSION_TEXT,
    amount: CONTRIBUTION_AMOUNT,
  };
  return [...entries, entry_expense, entry_liability];
}

function sumAmountsForUniqueAccounts(entries) {
  // Creating a Map to hold unique accounts and their summed amounts
  const map = entries.reduce((acc, entry) => {
    // Creating a string key to uniquely identify accounts with cost centers
    const key = `${entry.costCenter}-${entry.account}`;

    // If the key is already in the map, sum the amounts, otherwise add a new entry in the map
    if (acc.has(key)) {
      const k = acc.get(key);
      k.amount += entry.amount;
      k.text = k.text + "\n" + entry.text;
    } else {
      acc.set(key, entry); // Inserting the entire entry object
    }
    return acc;
  }, new Map());
  // Converting the Map values back into an array of ExportEntry objects.
  return Array.from(map.values());
}

export function processEntries(entries) {
  // We remove the E-Card service fees as it is zeroed out in K5
  const removedECardServiceFees = removeECardServiceFees(entries);
  // We flip the future contribution amounts to positive
  const flippedFutureContributionAmounts = flipFutureContributionAmounts(
    removedECardServiceFees,
  );
  // We adjust the cost centers to be 000000 if they are empty
  const adjustedCostCenters = adjustCostCenters(
    flippedFutureContributionAmounts,
  );
  // The communal accountant requires the SVAGA account to be divided into civil servant and elected bodies
  const adjustedForFutureContributions =
    adjustCostCenterForFutureContributions(adjustedCostCenters);
  // Change the costCenter for CivilServants to 1 if was 0 before
  const adjustedCostCenterForCivilServant = adjustCostCenterForCivilServant(
    adjustedForFutureContributions,
  );
  // We calculate the SVAGA account for civil servants and elected bodies
  const splitSVAGAContributions = splitSVAGAAccount(
    adjustedCostCenterForCivilServant,
  );
  // We calculate the Pension employer contribution for the mayor
  const withMayorPensionContribution =
    getPensionInsuranceEmployerContributionForMayor(splitSVAGAContributions);
  return sumAmountsForUniqueAccounts(withMayorPensionContribution);
}
