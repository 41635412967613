import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { csv2json } from "./CsvReader";
import { processEntries } from "./EntryAdapter";

function DropzoneComponent({ setBookingData, setInputFileName }) {
  // State for managing error messages
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Reset error message on new drop
      setErrorMessage("");

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => setErrorMessage("File reading was aborted.");
        reader.onerror = () => setErrorMessage("File reading has failed.");
        reader.onload = () => {
          try {
            setInputFileName(file.name);
            // Do whatever you want with the file contents
            const csv = reader.result;
            const entries = processEntries(csv2json(csv));
            setBookingData(entries);
          } catch (error) {
            console.error(`Problem processing file ${file.name}`);
            console.error(error);
            // Update error message state
            // Update error message state with German translation
            setErrorMessage(
              `Fehler bei der Verarbeitung der Datei ${file.name}. Bitte überprüfen Sie das Dateiformat.`,
            );
          }
        };
        // Assuming the provided csv was encoded in windows-1252
        reader.readAsText(file, "Windows-1252");
      });
    },
    [setInputFileName, setBookingData],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex mx-28">
      <div className="px-4 my-4 text-center text-grey-800 w-full">
        <div
          {...getRootProps()}
          className="p-4 border-dashed border-emerald-500 border-2"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Legen Sie die Dateien hier ab ...</p>
          ) : (
            <p>
              Ziehen Sie ihre Datei hierher oder klicken Sie, um ihre Datei
              auszuwählen.
            </p>
          )}
          {/* Display error message if any */}
          {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}

export default DropzoneComponent;
