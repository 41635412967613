import { createAccountHint } from "./XmlWriter";

export function TableComponent({ bookingData, tableRef }) {
  return (
    <div className="lg:w-1/2 px-4 w-full">
      <label className="block mb-2 text-lg font-medium text-gray-900">
        RZL
      </label>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table
          ref={tableRef}
          className=" focus:outline-none  w-full text-sm text-left text-gray-500"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Haushaltsstelle
              </th>
              <th scope="col" className="px-6 py-3 text-right">
                Betrag
              </th>
            </tr>
          </thead>
          <tbody>
            {bookingData.map((entry, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {`${createAccountHint(entry.account)}/${entry.costCenter}/${entry.account}`}
                </th>
                <td className="px-6 py-4 text-right">
                  {(entry.amount / 100).toFixed(2).replace(".", ",")} €
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
