import { useEffect } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

export function XmlAreaComponent({
  xmlContent: xmlContent,
  xmlAreaRef: xmlAreaRef,
}) {
  useEffect(() => {
    hljs.configure({ languages: ["xml"], ignoreUnescapedHTML: true });
    hljs.highlightAll();
  }, [xmlContent]);
  return (
    <div className="mt-4 lg:my-0 lg:w-1/2 px-4 w-full">
      <label className="block mb-2 text-lg font-medium text-gray-900 lg:text-right">
        K5
      </label>
      <pre>
        <code
          ref={xmlAreaRef}
          className="language-xml focus:outline-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-emerald-500 focus:border-emerald-500 background-color: rgb(226 232 240); overflow-auto"
        >
          {xmlContent}
        </code>
      </pre>
    </div>
  );
}
