import DropzoneComponent from "./DropzoneComponent";
import React, { useEffect, useRef } from "react";
import { AppHeaderComponent } from "./AppHeaderComponent";
import { TableComponent } from "./TableComponent";
import { XmlAreaComponent } from "./XmlAreaComponent";
import {} from "postcss";
import { DownloadButtonComponent } from "./DownloadButtonComponent";
import { MainHeadingComponent } from "./MainHeadingComponent";
import { json2xml } from "./XmlWriter";

export function App() {
  // Function to download the XML content as an XML file.
  function downloadXml() {
    const element = document.createElement("a");
    const file = new Blob([xmlContent], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    // removes the csv extension from the file name and replaces it with xml
    element.download = inputFileName.replace(".csv", ".xml");
    document.body.appendChild(element);
    element.click();
  }

  const [bookingData, setBookingData] = React.useState([]);
  const [inputFileName, setInputFileName] = React.useState("");
  const [xmlContent, setXmlContent] = React.useState("");
  const tableRef = useRef(null);
  const xmlAreaRef = useRef(null);
  const bookingDataRef = useRef(null);
  bookingDataRef.current = bookingData;
  // Updates the height of the xml area when the table entries change.
  useEffect(() => {
    if (tableRef.current && xmlAreaRef.current) {
      const tableHeight = tableRef.current.offsetHeight;
      xmlAreaRef.current.style.height = `${tableHeight}px`;
      setXmlContent(json2xml(bookingData));
    }
  }, [bookingData]);
  return (
    <div>
      <AppHeaderComponent />
      <MainHeadingComponent />
      <DropzoneComponent
        setBookingData={setBookingData}
        bookingDataRef={bookingDataRef}
        setInputFileName={setInputFileName}
      />
      {bookingData.length > 0 && (
        <>
          <div className="mt-8 flex flex-wrap mx-28">
            <TableComponent bookingData={bookingData} tableRef={tableRef} />
            <XmlAreaComponent xmlContent={xmlContent} xmlAreaRef={xmlAreaRef} />
          </div>
          <DownloadButtonComponent downloadXml={downloadXml} />
        </>
      )}
    </div>
  );
}
