import { parse } from "csv-parse/lib/sync";

function buildAccount(record) {
  const RZL_ACCOUNT_COLUMN = "Konto";
  return record[RZL_ACCOUNT_COLUMN];
}

function buildCostCenter(record) {
  const RZL_ACCOUNT_COST_CENTER = "Kostenstelle";
  return record[RZL_ACCOUNT_COST_CENTER];
}

function buildText(record) {
  const RZL_TEXT_COLUMN = "Text";
  return record[RZL_TEXT_COLUMN];
}

function createAmount(amount) {
  // In RZL we have , as decimal separator, in K5 we need . as decimal separator
  return parseInt(amount.replace(",", ""), 10);
}

function buildEntryAmount(record) {
  const RZL_CREDIT_COLUMN = "Soll (EUR)";
  const credit =
    record[RZL_CREDIT_COLUMN] === "0" ? "0,00" : record[RZL_CREDIT_COLUMN];
  const RZL_DEBIT_COLUMN = "Haben (EUR)";
  const debit =
    record[RZL_DEBIT_COLUMN] === "0" ? "0,00" : record[RZL_DEBIT_COLUMN];
  const RZL_ZERO_AMOUNT = "0,00";
  const IS_CREDIT_ZERO = credit !== RZL_ZERO_AMOUNT;
  const IS_DEBIT_ZERO = debit !== RZL_ZERO_AMOUNT;
  if (IS_CREDIT_ZERO ^ IS_DEBIT_ZERO) {
    // XOR operation to check only one of them is true
    return createAmount(IS_CREDIT_ZERO ? credit : debit);
  } else {
    throw new Error(
      IS_CREDIT_ZERO
        ? "Both credit and debit are not zero"
        : "Both credit and debit are zero",
    );
  }
}

function record2Entry(record) {
  let entry = {};
  entry.account = buildAccount(record);
  entry.costCenter = buildCostCenter(record);
  entry.text = buildText(record);
  entry.amount = buildEntryAmount(record);
  return entry;
}

export function csv2json(csv) {
  let records = parse(csv, {
    columns: true,
    skip_empty_lines: true,
    delimiter: ";",
  });
  return records.map((record) => record2Entry(record));
}
