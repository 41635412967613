export function DownloadButtonComponent({ downloadXml: downloadXml }) {
  return (
    <div className="flex flex-col items-center my-20">
      <button
        onClick={downloadXml}
        type="button"
        className="text-white bg-emerald-500 hover:bg-emerald-800 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
      >
        Download
      </button>
    </div>
  );
}
